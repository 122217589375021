import React from 'react'
import NewTemplate from '../Components/NewTemplate/NewTemplate'

export default function newDocument() {
    return (
        <div>
            <NewTemplate/>
        </div>
    )
}
